.header {
  position: fixed;
  display: flex;
  top: 0;
  width: 100%;
  height: 200px;
  // max-width: 100vw;
  top: 0;
  line-height: 4em;
  z-index: -1;
  min-height: unset;
  transition: all 0.5s ease-in-out;
  &-blurr {
    position: absolute;
    top: 0;
    width: 100%;
    height: 150px;
    opacity: 0.6;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    -webkit-mask-image: linear-gradient(to bottom, #000 35%, transparent 100%);
    mask-image: linear-gradient(to bottom, #000 35%, transparent 100%);
    z-index: 2;
  }
  &-background {
    position: absolute;
    top: 0;
    width: 100%;
    height: 150px;
    opacity: 0;
    transition: all 0.5s ease-in-out;
    -webkit-mask-image: linear-gradient(to bottom, #000 35%, transparent 100%);
    mask-image: linear-gradient(to bottom, #000 35%, transparent 100%);
    &.light {
      z-index: 0;
      transition: all 0.5s ease-in-out;
      background-image: radial-gradient(
        at top center,
        black,
        rgba(0, 0, 0, 0) 59%
      );
      &.visible {
        opacity: 0.6;      
      }
    }
    &.dark {
      transition: all 0.5s ease-in-out;
      background-image: radial-gradient(
        at top center,
        #fff,
        rgba(0, 0, 0, 0) 59%
      );
      &.visible {
        opacity: 0.6;
      }
      z-index: 1;
    }
  }
  opacity: 0;

  &.header-active {
    opacity: 1;
    z-index: 100;
    transition: opacity 0.5s ease-in-out;
  }

  svg {
    path {
      fill: #aaaaad;
      transition: 1s;
      &.light {
        fill: #a8a3a0;
      }
      &.dark {
        fill: #DADAE0;
      }
      &.designer {
        fill: #494949;
      }
      &.motorcyclist {
        fill: #fff
      }
    }
  }
}
.section-body-header {
  width: 100%;
  height: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  color: #a8a3a0;
  transition: all 1s ease-in-out;
  position: relative;
  z-index: 3;

  &-logo-holder {
    width: 33%;
    display: flex;
    justify-content: center;
    height: 111px;
  }
  .logo {
    width: 111px;
    height: 111px;
    &.white {
      color: white;
    }
  }
  .tag {
    font-size: 36px;
    width: 33%;
    display: flex;
    justify-content: flex-end;
    margin-right: 1%;
    cursor: pointer;
    white-space: nowrap;
    transition: all 0.5s ease;
    &.small {
      font-size: 36px;
    }

    &.dark {
      color: #DADAE0;
    }
    &.designer {
      color: #494949;;
    }
    &.motorcyclist {
      color: #fff
    }
  }
  .name {
    font-size: 32px;
    width: 33%;
    margin-left: 1%;
    line-height: 1;
    transition: all 0.5s ease;

    &.dark {
      color: #DADAE0;
    }
    &.designer {
      color: #494949;;
    }
    &.motorcyclist {
      color: #fff
    }
  }
}


.white-logo {
  fill: #aaaaad;
}
.header-hidden {
  display: none;
}

@media only screen and (max-width: 750px) {
  .section-body-header {
    height: 100px;
  }
  .section-body-header .section-body-header-logo-holder {
    height: 55.5px;
  }
  .section-body-header .logo {
    width: 55.5px;
    height: 55.5px;
  }
  .section-body-header {
    .tag,
    .name {
      font-size: 16px !important;
    }
  }
  .header {
    &-blurr {
      height: 75px;
    }
  }
}
