.design-row {
  margin-top: 24px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  &-text {
    margin-top: 100px;
    text-align: center;
    font-family: 24px;
    color: #76767a;
  }

  &.jcc {
    justify-content: center;
  }
  &.fdc {
    flex-direction: column;
  }

  .small-design,
  .small-medium-design,
  .medium-design,
  .large-design {
    // border-color: #979797;
    display: flex;
    justify-content: center;
    align-items: center;
    &.aie {
      align-items: flex-end;
    }
    &.ais {
      align-items: flex-start;
    }
    &.jend {
      justify-content: end;
    }
    &.jstart {
      justify-content: start;
    }
    &.jcenter {
      justify-content: center;
    }
  }
  .small-design {
    width: 32.3%;
    max-height: 363px;
    img {
      height: 100%;
      // cursor: pointer;
      &.logo {
        width: 75%;
        height: 75%;
      }
      &.small-gap-image {
      }
    }
  }
  .small-medium-design {
    width: 67%;
    max-height: 363px;
    img {
      height: 100%;
      width: 100%;
      // cursor: pointer;
    }
  }
  .medium-design {
    width: 48%;
    &.h100 {
      max-height: unset;

      height: 100%;
    }
    img {
      height: 100%;
      width: 100%;
      // cursor: pointer;
      &.small-gap-image {
      }
    }
  }
  .large-design {
    width: 100%;
    max-height: 400px;
    &.h100 {
      max-height: unset;

      height: 100%;
    }
    &.mt50 {
      margin-top: 50px;
    }

    img {
      width: 100%;
      height: 100%;
      // cursor: pointer;
    }
  }
}
