.photo-page {
  height: 100vh;
  .section-body-photo-page {
    margin-top: 200px;
    width: 100%;
    height: 100%;
    display: flex;
    max-width: 1200px;
    flex-direction: column;
    color: #a8a3a0;
    transition: all 0.3s ease;
    position: relative;
    z-index: 100000;
  }

  .photo-set-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    max-height: 200px;
    justify-content: center;
    margin-top: 20px;
    &.reverse {
        flex-direction: row-reverse;

    }



    &-thumbs {
      width: 10%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      &-line {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        &-thumbnail {
          width: 45%;
        }
      }
    }
    &-info {
      width: 75%;
      color: #76767a;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: 40px;
      &-title {
        font-size: 30px;
      }
      &-description {
        font-size: 24px;
      }
    }
  }
}


