.splash {
  height: 100vh;
  
  .section-body-splash {
    margin-top: 100px;
    width: 100%;
    height: 100%;
    display: flex;
    max-width: 1200px;
    flex-direction: row;
    color: #a8a3a0;
    transition: all 0.3s ease;
    position: relative;
    justify-content: center;
    z-index: 100000;
    .tag-holder {
      display: flex;
      flex-direction: column;
      font-size: 166px;
      line-height: 1;
      width: 70%;
      align-items: flex-end;
      white-space: nowrap;
      letter-spacing: -10px;
      &.small {
        font-size: 36px;
        letter-spacing: -2px;
      }
      .link {
        cursor: pointer;
        margin-right: 1%;
        opacity: 0;
        animation: fadeIn 3s forwards;
        &.active {
          animation: none;
          opacity: 1;

        }
      }
      @for $i from 1 through 10 {
        .link:nth-child(#{$i}n) {
            animation-delay: #{$i * 0.2}s;
        }
    }
    }
    &-logo-holder {
      position: absolute;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      text-align: center;
      display: flex;
      justify-content: center;
      height: 100vh;
      width: 100vw;
      align-items: center;
      svg {
        height: 90%;
        path {
          fill: #ffffff;
        }
      }
    }
    &-text-holder {
      display: flex;
      flex-direction: row;
      width: 97%;
      z-index: 100;
    }

    .name {
      font-size: 3.5rem;
      width: 30%;
      line-height: 1;
      color: #fff;
     
    }
    &-logo-holder {
      width: 100%;
    }
  }
}

@media only screen and (max-width: 1100px) {
  .splash {
    .section-body-splash {
      max-width: 100%;
     
      &-logo-holder {
        svg {
          width: 90%;
        }
      }
      .tag-holder {
        font-size: 120px;
      }
      .name {
        font-size: 52px;
      }
    }
  }
}
@media only screen and (max-width: 1000px) {
  .splash {
    .section-body-splash {
      max-width: 100%;
      &-text-holder {
      }
      .tag-holder {
        font-size: 3rem;
        letter-spacing: -1px;
      }
      .name {
        font-size: 3rem;
      }
    }
  }
}
@media only screen and (max-width: 750px) {
  .splash {
    .section-body-splash {
      margin-top: 50px;
      max-width: 100%;
      &-logo-holder {
        svg {
          height: unset;
        }
      }
      .tag-holder {
        font-size: 3rem;
        letter-spacing: -1px;
      }
      .name {
        font-size: 2rem;      }
    }
  }
}
// @media only screen and (max-width: 600px) {
//   .splash {
//     .section-body-splash {
//       margin-top: 50px;
//       max-width: 100%;
//       &-logo-holder {
//         svg {
//           height: unset;
//         }
//       }
//       .tag-holder {
//         font-size: 60px;
//       }
//       .name {
//         font-size: 24px;
//       }
//     }
//   }
// }
// @media only screen and (max-width: 500px) {
//   .splash {
//     .section-body-splash {
//       margin-top: 50px;
//       max-width: 100%;
//       &-logo-holder {
//         svg {
//           height: unset;
//         }
//       }
//       .tag-holder {
//         font-size: 42px;
//       }
//       .name {
//         font-size: 24px;
//       }
//     }
//   }
// }
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}