
$number-of-links: 5;

$hamburger-menu-width: 50px;
$hamburger-menu-height: 40px;
$hamburder-menu-x-position: 30px;
$hamburder-menu-y-position: 30px;

$hamburger-icon-background-color: #ffffff;
$hamburger-icon-width: 50px;
$hamburger-icon-height: 6px;
$hamburger-icon-rotate-deg: 45deg;
$hamburger-icon-transform-time: 350ms;

$overlay-background-color: rgba(0, 0, 0, 0.5);
$overlay-starting-position-x: $hamburder-menu-x-position +
  ($hamburger-menu-width / 2);
$overlay-starting-position-y: $hamburder-menu-y-position +
  ($hamburger-menu-height / 2);
$overlay-animation-timing: 125ms;

$menu-link-default-color: #8A8A8F;
$menu-link-hover-color: #ffffff;
$menu-link-emboss-color: rgba(0, 0, 0, 0.8);
$menu-link-shine-color: #ffffff;
$menu-link-font-size: 72px;
$menu-link-spacing: 25px;
$menu-link-shine-size: 25px;
$menu-link-hover-transition-time: 175ms;
$menu-link-hover-transition-time-scale: 275ms;
$menu-link-show-transition-time: 350ms;



.menu-trigger {
}
.close-button {
  margin: 50px 0;
  cursor: pointer;
}

// ---------- OVERLAY AREA
.overlay {
  width: 0;
  height: 0;
  
  z-index: 500;
  position: absolute;
  overflow: hidden;
  display: flex;
  // align-items: start;
  justify-content: center;
  transition: width 0s linear $overlay-animation-timing,
    height 0s linear $overlay-animation-timing;
  animation: overlay-hide $overlay-animation-timing ease-out forwards;
  max-width: 100vw;
  &-holder {
    width: 1200px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .section-body-header {
    align-items: unset;
    margin-top: 45px;
    margin-right: 11px;
    // padding-right: 1rem;
    svg {
      path {
        fill: #a8a3a0 !important;
      }
    }
  }
  .tag-holder {
    
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 33%;
    height: fit-content;
    .link {
      font-size: 72px;
      &.active {
        color:#fff;  
      }
      &:hover {
            color:#fff;  
      }
    }
  }

}

.menu-trigger  .overlay {
  width: 100%;
  height: 100%;
  top: 0;
  position: fixed;
  backdrop-filter: blur(10px);
  transition: none;
  animation: overlay-mask $overlay-animation-timing ease-out forwards;
  overflow: hidden;
}

@keyframes overlay-mask {
  @for $i from 0 through 100 {
    #{$i + 0%} {
      background-image: radial-gradient(
        circle
          at
          calc(100vw - #{$overlay-starting-position-x})
          $overlay-starting-position-y,
        $overlay-background-color ($i + 0%),
        transparent ($i + 0%)
      );
    }
  }
}

@keyframes overlay-hide {
  @for $i from 100 through 0 {
    #{$i + 0%} {
      background-image: radial-gradient(
        circle
          at
          calc(100vw - #{$overlay-starting-position-x})
          $overlay-starting-position-y,
        $overlay-background-color (100 - $i + 0%),
        transparent (100 - $i + 0%)
      );
    }
  }
}


@media only screen and (max-width: 1000px) {
 
}


@media only screen and (max-width: 750px) {
  .overlay {
   
    &-holder {
     
    }
    .section-body-header {
      margin-top: 25px;
  
    }
    .tag-holder {
    
      .link {
        font-size: 24px;
        &.active {
        }
        &:hover {
        }
      }
    }
  }
}
