.motor {
  background-color: #000;
  padding-bottom: 25vh;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .section-photo {
    width: 100%;
    max-width: 100%;
    img {
      width: 100%;
      // object-fit: cover;
    }
  }
}

.section-body-motor {
  max-width: 1200px;
  font-family: "Fakt-Normal";
  /* margin-top:100px; */
  opacity: 0.2;
  transition: all 1.5s ease-in-out;
  &.is-intersecting {
    opacity: 1;
  }
  &-text {
    margin-top: 5%;
    display: flex;
    color: #76767a;
    font-size: 24px;
    flex-direction: column;
    max-width: 700px;
    width: 100%;
    font-size: 24px;
    a {
      font-size: 36px;
      text-decoration: none;
      color: #dadae0;
      &:hover {
          color: #fff
      }
    }
  }
  &-logos {
    margin-top: 50px;
    display: flex;
    justify-content: flex-start;
    width: 100%;
    max-width: 700px;
  }
}

.ml-50 {
  margin-left: 50px;
}

@media only screen and (max-width: 750px) {
  .section-body-motor {
    &-text {
      font-size: 12px;
      &-holder {
        padding: 20px;
      }
      h2 {
        a {
          font-size: 24px;
          letter-spacing: -2px;
        }
        
      }
    }
    &-logos {
      img:nth-child(1) {
        margin-left: 20px;
      }
      img {
        height: 50px;
      }
    }
    .section-photo {
    }
  }
  .ml-50 {
    margin-left: unset;
  }
}
