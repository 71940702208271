.ReactModal__Html--open,
.ReactModal__Body--open {
  overflow: hidden; /* prevents background page from scrolling when the modal is open */
}

.ReactModal__Overlay {
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.75);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  display: flex;
  justify-content: center;
  opacity: 0;
  //   transform: translateX(-100px);
  transition: all 500ms ease-in-out;
}

.ReactModal__Content {
  background: #121314;
  width: 100%;
  max-width: 1200px;
  max-height: 1200px;
//   box-shadow: 0 0 30px 0 rgb(0 0 0 / 25%);
  overflow-y: auto;
  position: relative;
  margin-top: 2%;
}

.ReactModal__Overlay {
}

.ReactModal__Overlay--after-open {
  opacity: 1;
//   transform: translateX(0px);
}

.ReactModal__Overlay--before-close {
  opacity: 0;
  // transform: translateX(-100px);
}

.design-modal {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: unset;
  align-items: center;
  position: relative;
  &-header {
    width: 95%;
    display: flex;
    flex-direction: column;
    position: sticky;
    display: flex;
    top: 0;
    width: 100%;
    height: 200px;
    &-toolbar {
    margin-top: 2%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      height: 100px;
      &-button {
        display: flex;
        color: #dadae0;
        font-size: 36px;
        align-items: center;
        justify-content: center;
        &-text {
          margin-left: 15px;
          &.mr15 {
            margin-right: 15px;
          }
        }
        &.clickable {
            cursor: pointer;
        }
        &.hidden {
          opacity: 0;
        }
      }
      svg {
        height: 100%;
        path {
          fill: #dadae0;
        }
      }
    }
  }
  &-info {
    display: flex;
    flex-direction: row;
    width: 95%;
    &-logo {
      max-width: 82px;
      max-height: 82px;
      img {
        width: 100%;
      }
    }
    &-text {
      margin-left: 10px;
      display: flex;
      flex-direction: column;
      &-firstline {
        color: rgba(218, 218, 224, 1);
        font-family: "FaktPro-Medium";
        font-size: 36px;
        letter-spacing: -1;
      }
      &-secondline {
        color: rgba(218, 218, 224, 1);
        font-family: "FaktPro-Medium";
        font-size: 24px;
        opacity: 0.3;
        letter-spacing: -1;
      }
    }
  }
  &-header-photos {
    width: 95%;
    display: flex;
    flex-direction: column;
  }
  &-description {
    color: #dadae0;
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    &-text {
      width: 50%;
    }
  }
  &-footer-photos {
    width: 95%;
    display: flex;
    flex-direction: column;
  }
}
