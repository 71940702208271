.photo {
  background-color: #f0edeb;
}
.section-body-photo {
  margin-top: 238px;
  margin-bottom: 50vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  font-family: "Fakt-Normal";
  /* margin-top:100px; */
  align-items: center;
  position: relative;

  opacity: 0.2;
  transition: all 1.5s ease-in-out;
  &.is-intersecting {
    opacity: 1;
  }

  .photo-grid-line-holder {
    max-width: 92.6%;
    width: 100%;
    .photo-grid-line {
      width: 100%;
      display: flex;
      flex-direction: row;
      margin-bottom: 24px;
      &-container {
        display: flex;
        flex-direction: column;
        &-row {
          display: flex;
          flex-direction: row;
        }
      }
      &-text {
        margin-top: 100px;
        text-align: center;
        font-family: 24px;
        color: #a8a3a0;
        a {
          text-decoration: none;
          color: #a8a3a0;
          font-family: "Fakt-Normal";
          &:hover {
            color: #76767a;
          }
        }
      }
      // justify-content: space-between;
      &.line-1 {
        max-height: 736px;
        justify-content: space-between;
      }
      &.line-2 {
        max-height: 988px;
        justify-content: space-between;
      }
      &.line-3 {
        max-height: 480px;
        justify-content: space-between;
      }
      &.text-line {
        justify-content: center;
      }
    }
  }
  .pictures-container {
    width: 48%;
    &-holder {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      &-line {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
      &.columflex {
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
        .picture-medium-large {
          height: 49.5%;
        }
      }
      
    }
    
    &.medium-picture-width {
      width: 31.409%;
      svg {
        height: 100%;
        width: 100%;
      }
    }
    &.xl-picture-width {
      width: 66.235%;
      max-height: 988px;
      svg {
        height: 100%;
        width: 100%;
      }
    }
    .picture-small {
      width: 47.45%;
      max-height: 356px;
      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
 
  .picture-medium {
    width:  31.408%;
    max-height: 480px;
    img {
      cursor: pointer;
      width: 100%;
      height: 100%;
    }
    &.m31 {
      margin-left: 2.295%;    }
    &.m342 {
      margin-left: 3.42%;
    }
  }
}
.picture-medium-large {
  width: 100%;
  max-height: 480px;
  img {
    cursor: pointer;
    width: 100%;
    height: 100%;
  }
}

.picture-large {
  width: 49.23%;
  svg {
    width: 100%;
    height: 100%;
  }
}
.picture-small-inline {
  width: 22.769%;
  max-height: 356px;
  svg {
    width: 100%;
    height: 100%;
  }
}

@media only screen and (max-width: 1000px) {
  .picture-small {
    img {
      object-fit: unset !important;
    }
  }

  .picture-medium-large {
    img {
      object-fit: cover;
    }
  }
}

@media only screen and (max-width: 750px) {
  .section-body-photo {
    margin-top: 100px;
    margin-bottom: 100px;
  }
}
