section {
  &.design {
    background-color: #121314;

    .section-body-design {
      margin-top: 238px;
      margin-bottom: 50vh;

      width: 100%;
      height: 100%;
      display: flex;
      max-width: 1100px;
      flex-direction: column;
      color: #a8a3a0;
      transition: all 0.3s ease;
      position: relative;
      align-items: center;
      opacity: 0.2;
      transition: all 1.5s ease-in-out;
      &.is-intersecting {
        opacity: 1;
      }

      
    }
  }
  .grayish {
    background-color: #1e1e1e;
  }
  .blackish {
    background-color: #0a0a0a;
  }
}
@media only screen and (max-width: 1000px) {
  section {
    &.design {
      background-color: #121314;
      .section-body-design {
        .design-row {
          width: 98%;
          .small-design,
          .small-medium-design,
          .medium-design,
          .large-design {
            &.aie {
            }
            &.ais {
            }
          }
          .small-design {
            img {
              width: 100%;

              &.logo {
                width: 75%;
                height: 75%;
              }
              &.small-gap-image {
                height: 75%;
              }
            }
          }
          .small-medium-design {
            img {
            }
          }
          .medium-design {
            img {
              width: 100%;
              height: unset;
              &.small-gap-image {
                height: 75%;
              }
            }
          }
          .large-design {
            img {
            }
          }
        }
      }
    }
    .grayish {
    }
    .blackish {
    }
  }
}

@media only screen and (max-width: 750px) {
  section {
    &.design {
      background-color: #121314;
      .section-body-design {
        margin-top: 50px;

        .design-row {
          .small-design,
          .small-medium-design,
          .medium-design,
          .large-design {
            &.aie {
            }
            &.ais {
            }
          }
          .small-design {
            img {
              width: 100%;
              &.logo {
              }
              &.small-gap-image {
              }
            }
          }
          .small-medium-design {
            img {
            }
          }
          .medium-design {
            img {
              width: 100%;
              &.small-gap-image {
              }
            }
          }
          .large-design {
            img {
            }
          }
        }
      }
    }
    .grayish {
    }
    .blackish {
    }
  }
}
