/**
 *
 * Table of Contents
 * --------------------------------------------------
 *  0.0   - Base Styles
 *  1.0   - Parallax Section 


 * --------------------------------------------------
 */

/* 
    0   - Base Styles 
*/
@font-face {
  font-family: "Fakt-Normal";
  src: local('Fakt-Normal'),  url(static/fonts/Fakt-Normal.woff2) format("woff2");
}

@font-face {
  font-family: "Fakt-Medium";
  src: local('Fakt-Medium'),  url(static/fonts/Fakt-Medium.woff2) format("woff2");
}

body {
  margin: 0;
  font-family: "Fakt-Medium", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: #f0edeb;
//     -ms-overflow-style: none;  /* Internet Explorer 10+ */
//     scrollbar-width: none;  /* Firefox */
// &::-webkit-scrollbar {
//   display: none;  
// }
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

section {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  //   position: relative;
  //   min-height: 100vh;
}

// .section-photo {
//   height: 100%;
//   position: relative;
//   z-index: 0;
//   width: 100%;
// }

.section-photo img {
  height: 100%;
  width: 100%;
}

@media only screen and (max-width: 700px) {
  img {
    // object-fit: contain;
  }
}

.noscroll {
  overflow: auto;

  .header {
  }
}

$fade-animation-duration: 250ms;
$fade-animation-timing-function: ease;

$color-main: #fff;

$toolbar-padding: 8px;
$icon-button-padding: 8px;
$icon-size: 32px;

$carousel-padding: 16px;
$carousel-spacing: 30%;

$swipe-animation-duration: 500ms;
$swipe-animation-timing-function: ease-out;

.yarl_ {
  &_portal {
    position: fixed;
    inset: 0;
    z-index: 9999;
    overflow: hidden;
    opacity: 0;
    transition: opacity
      var(--yarl__fade_animation_duration, $fade-animation-duration)
      $fade-animation-timing-function;

    &_open {
      opacity: 1;
    }
  }

  &_container,
  &_carousel,
  &_slide {
    user-select: none;
    touch-action: none;
  }

  &_container {
    /* width: 100%; */
    height: 100%;
    position: relative;
    overflow: hidden;
    color: $color-main;
    background-color: rgba(0, 0, 0, 0.75);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    outline: 0;
  }

  &_carousel {
    position: absolute;
    overflow: hidden;
    inset: 0;
  }

  &_flex_center {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
  }

  &_slide {
    position: absolute;
    overflow: hidden;
    inset: 0;

    padding: var(--yarl__carousel_padding, $carousel-padding);

    transform: translate3d(
      calc(
        var(--yarl__slide_offset, 0) *
          (100% + var(--yarl__carousel_spacing, $carousel-spacing))
      ),
      0,
      0
    );
    transition: transform
      var(--yarl__swipe_animation_duration, $swipe-animation-duration)
      $swipe-animation-timing-function;

    .yarl__container_swipe & {
      transform: translate3d(
          calc(
            var(--yarl__slide_offset, 0) *
              (100% + var(--yarl__carousel_spacing, $carousel-spacing))
          ),
          0,
          0
        )
        translate3d(var(--yarl__swipe_offset, 0px), 0, 0);
      transition: unset;
    }

    &_image {
      width: 100%;
      height: 100%;
      object-fit: contain;
      user-select: none;
      -webkit-user-select: none;
      -webkit-touch-callout: none;

      &_loading {
        opacity: 0;
      }
    }

    &_placeholder {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      line-height: 0;
    }

    &_loading {
      color: rgba($color-main, 0.8);
      animation: yarl__delayed_fadein 1s linear;

      & line {
        animation: yarl__stroke_opacity 1s linear infinite;
      }

      @for $i from 1 through 8 {
        & line:nth-of-type(#{$i}) {
          animation-delay: #{$i * 0.125 - 2}s;
        }
      }
    }

    &_error {
      width: 48px;
      height: 48px;
      color: red;
    }
  }

  @media (prefers-reduced-motion) {
    &_portal,
    &_slide {
      transition: unset;
    }

    &_slide_loading,
    &_slide_loading line {
      animation: unset;
    }
  }

  &_toolbar {
    position: absolute;
    inset: 0 0 auto 0;
    display: flex;
    //justify-content: center;
    padding: $toolbar-padding;
    gap: $toolbar-padding;
    svg {
      path {
      }
    }
  }

  &_icon {
    width: $icon-size;
    height: $icon-size;
  }

  &_button {
    appearance: none;
    background-color: transparent;
    cursor: pointer;
    border: 0;
    margin: 0;
    outline: none;
    line-height: 0;
    padding: $icon-button-padding;
    color: #8a8a8f;
    // filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, .8));
    -webkit-tap-highlight-color: transparent;

    &:focus {
      color: $color-main;
    }

    &:focus:not(:focus-visible) {
      color: rgba($color-main, 0.8);
    }

    &:focus-visible {
      color: $color-main;
    }

    @media (hover: hover) {
      &:hover,
      &:focus:hover,
      &:focus-visible:hover {
        color: $color-main;
      }
    }

    &:disabled {
      color: rgba($color-main, 0.4);
      cursor: default;
    }
  }

  &_navigation {
    &_prev,
    &_next {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      padding: $toolbar-padding + 2 * $icon-button-padding $toolbar-padding +
        $icon-button-padding;
    }

    &_prev {
      left: 0;
      svg {
        path {
          transform: rotate(180deg);
          /* transform: rotate(115deg); */
          -webkit-transform-origin: center;
        }
      }
    }

    &_next {
      right: 0;
    }
  }

  &_no_scroll {
    height: 100%;
    overflow: hidden;
  }

  &_pad_scrollbar {
    padding-right: var(--yarl__scrollbar_padding, 17px);
  }
}

@keyframes yarl__delayed_fadein {
  0% {
    opacity: 0;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes yarl__stroke_opacity {
  from {
    stroke-opacity: 1;
  }
  to {
    stroke-opacity: 0.125;
  }
}

$captions-font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
$captions-background-color: rgba(0, 0, 0, 0.5);
$captions-padding: 16px;

$captions-font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
$captions-background-color: rgba(0, 0, 0, 0.5);
$captions-padding: 16px;

.yarl__slide {
  &_title {
    font-family: $captions-font-family;
    font-size: 1.25rem;
    font-weight: 700;
    max-width: calc(100% - 2 * 48px - 8px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &_container {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      padding: $captions-padding;
      width: 100%;
      display: flex;
      justify-content: center;
      // background: $captions-background-color;
    }
  }

  &_description {
    font-family: $captions-font-family;
    font-size: 1rem;
    line-height: 1.2;
    font-weight: 500;
    overflow: hidden;
    hyphens: auto;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: var(--yarl__slide_description_max_lines, 3);
    text-align: var(--yarl__slide_description_text_align, start);

    &_container {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      padding: $captions-padding;
      width: 100%;
      display: flex;
      justify-content: center;
      // background: $captions-background-color;
    }
  }
}
.gs_reveal {
  opacity: 0;
  visibility: hidden;
  will-change: transform, opacity;
}

.spinner-container {
  z-index: 100;
  display: flex;
  justify-content: center;
  align-content: center;
  justify-items: center;
  flex-direction: column;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100vh;
  opacity: 1;
  transition: all 0.5s ease-in-out;
  max-height: 100vh;
  overflow: hidden;
  &.hidden {
    opacity: 0;
  }
  svg {
    width: 25px;
    height: 25px;

    fill: black;
    animation: rotate-loader 2s infinite cubic-bezier(0.8, 0.5, 0, 1);
  }

  @keyframes rotate-loader {
    100% {
      transform: rotate(1turn);
    }
  }
}

.content-hidden {
  opacity: 0;
  transition: all 0.5s ease-in-out;
  &.loaded {
    opacity: 1;
  }
}
.emptydiv {
  width: 50px;
}

@media only screen and (max-width: 750px) {
  .yarl__navigation_prev,
  .yarl__navigation_next {
    display: none;
  }
}

@keyframes loadingAnimation {
  0% {
    fill: #fff;
  }
  50% {
    fill: #ccc;
  }
  100% {
    fill: #fff;
  }
}
.placeholderSvg {
  position: absolute;
  z-index: 5000;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  animation: loadingAnimation 2s infinite;
  width: 100%;
  height: 100%;
}
figure {
    margin: 0px !important;
    width: 100%;
    height: 100%;
}


@keyframes fade {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  #right {
    animation-delay: 1s;
  }
  
  #center {
    animation-delay: 2s;
  }
  
  .shape {
    opacity: 1;
    animation-fill-mode: both;
    animation-iteration-count: infinite;
    animation-name: fade;
    animation-duration: 1s;
  }

 
