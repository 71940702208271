.about {
  background-color: #f0edeb;
  .section-body-about {
    margin-top: 200px;
    margin-bottom: 50vh;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    max-width: 1200px;
    font-family: "Fakt-Normal";
    .section-photo {
      width: 1000px;
      height: 526px;
      border-radius: 32px;
      img {
        height: 100%;
        object-fit: contain;
      }
    }

    &-content {
      margin-top: 5%;
      position: relative;
      display: flex;
      &-text {
        margin-bottom: 100px;
        margin-left: 50px;
        color: #a8a3a0;
        font-size: 24px;
        &-large {
          font-size: 48px;
          line-height: 64px;
          letter-spacing: -1pt;
        }
        a {
          text-decoration: none;
          color: #706B68;
          &:hover {
            color: #fff;
          }
        }
      }
      &-hi {
        width: 100%;
        img {
          width: 100%;
          max-width: 300px;
        }
      }
      &-contact {
        margin-top: 100px;
        display: flex;
        flex-direction: column;
        font-size: 48px;
        &-email,
        &-twitter,
        &-instagram {
          text-decoration: none;
          width: fit-content;
          color: #706B68;    width: fit-content;
          &:hover {
            color: #fff;
          }
        }
        &-email {
          margin-top: 10px;
        }
      }
    }

    opacity: 0.2;
    transition: all 1.5s ease-in-out;
    &.is-intersecting {
      opacity: 1;
    }
  }
  @media only screen and (max-width: 1000px) {
    .section-body-about {
      max-width: 100%;
      margin-top: 50px;
      .section-photo {
        width: 100%;
        height: unset;
        border-radius: 0px;
        img {
        }
      }
    }
  }
}

@media only screen and (max-width: 750px) {
  .section-body-about {
    &-content {
      display: flex;
      flex-direction: column;
      // margin-top: -5%;
      &-text {
        padding: 10px;
        font-size: 12px !important;

        margin-left: 0px;
        margin-top: 20px;
        &-large {
          font-size: 24px !important;

          line-height: 32px !important;
        }
      }
      &-hi {
      
      }
      &-contact {
        margin-top: 100px;
        display: flex;
        flex-direction: column;
        font-size: 36px;
        &-email,
        &-twitter,
        &-instagram {
          text-decoration: none;
          color: #a8a3a0;
        }
      }
    }
    .section-photo {
      width: 100%;
      margin-top: 50px;
    }
  }
}

@media only screen and (max-width: 500px) {
  .section-body-about {
    &-content {
      &-text {
        &-large {
        }
      }
      &-hi {
        img {
          margin-top: -10%;
          max-width: 200px !important;
        }
      }
      &-contact {
        margin-top: 50px !important;
        font-size: 24px !important;
      }
    }
  }
}
